import * as Actions from "../actions/promocion-actions";

const initialState = {
    entities: [],
    loading: false,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        /* CRUD  */
        case Actions.GET_HOME_PROMOCION: {
            return {
                ...state,
                loading: true,
            };
        }
        case Actions.GET_HOME_PROMOCION_SUCCESS: {
            return {
                ...state,
                loading: false,
                entities: action.payload,
            };
        }
        case Actions.GET_HOME_PROMOCION_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        }
        default:
            return state;
    }
};

export default reducer;
